<template>
  <!--
    The tr attributes need to match the ./RowExpandable.vue tr attributes
    for selectability to work correctly
  -->
  <tr
    modern-tr
    :style="
      Object.assign({}, styles.row, {
        ...(focusable && { cursor: 'pointer' })
      })
    "
    :data-id="dataId"
    @click="rowClicked({ row, rowIndex })"
    :selected="selected"
    :class="focusable && selected ? 'row-is-selected' : ''"
  >
    <!-- <tr v-if="computedRows && computedRows.length > 0" v-for="(row, rowIndex) in computedRows" tabindex="0" :aria-label="'row ' + parseInt(rowIndex + 1)"> -->

    <!-- eslint-disable vue/require-v-for-key -->
    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <td v-for="(entry, key, index) in row" v-if="key !== '_meta'" :i="index" :style="styles.td">
      <!-- eslint-enable vue/require-v-for-key -->

      <!-- mobile labels -->
      <!-- <div v-if="key === '_expandable'"></div> -->
      <div v-if="expandableRows && key !== '_expandable'" class="mobile-label">
        {{ columns && columns[index - 1] && columns[index - 1].label }}
      </div>
      <div v-else-if="key !== '_expandable'" class="mobile-label">
        {{ columns && columns[index] && columns[index].label }}
      </div>

      <!-- expand/collapse icon -->
      <!-- "expandable-icon" is used for testing -->
      <div
        class="expandable-icon-container"
        @click="onExpand(rowIndex)"
        v-if="
          key === '_expandable' &&
            entry &&
            entry.show &&
            (entry.showIcon || entry.showIcon == undefined)
        "
        expandable-icon
        :style="{
          marginLeft: '-6px',
          marginTop: '4px'
        }"
      >
        <b-icon
          class="expandable-arrow-icon"
          :style="styles.icon"
          pack="mdi"
          icon="chevron-right"
          custom-size="mdi-24px"
          type="is-primary"
        ></b-icon>
      </div>

      <div v-if="expandableRows && key === '_expandable'" class="mobile-label"></div>

      <!-- expandableRows, but this row is not expandable -->
      <!-- "no-expandable-icon" is used for testing -->
      <div v-else-if="key === '_expandable' && entry && entry.show === false" no-expandable-icon />

      <!-- component -->
      <component
        v-else-if="entry && entry.component"
        :is="entry.component"
        v-bind="entry.props"
      ></component>

      <span
        :style="{
          color: entry.date === 'January 1, 1900' ? `white` : `black`
        }"
        v-else-if="entry && entry.date"
        t
        >{{ entry.date === 'January 1, 1900' ? '' : entry.date }}</span
      >

      <!-- "t" denotes that this is the text element (for tests) -->
      <span
        v-else-if="
          (typeof entry === 'string' || typeof entry === 'number' || typeof entry === 'boolean') &&
            key !== '_expandable' &&
            (!readMoreColumn || readMoreColumn !== columns[index].label)
        "
        t
      >
        {{
          columns[index] &&
            (typeof columns[index].prefix === 'string' ? columns[index].prefix : null)
        }}<span
          v-if="htmlColumn.length > 0 && htmlColumn === columns[index].label"
          v-html="entry"
        ></span
        ><span v-else>{{ entry }}</span></span
      >
      <span
        v-else-if="
          (typeof entry === 'string' || typeof entry === 'number' || typeof entry === 'boolean') &&
            key !== '_expandable' &&
            readMoreColumn &&
            readMoreColumn === columns[index].label
        "
        t
        >{{
          columns[index] &&
            (typeof columns[index].prefix === 'string' ? columns[index].prefix : null)
        }}
        <div class="content">
          <p>
            {{ entry.length > 300 ? entry.substring(0, 300) : entry }}
          </p>
        </div>
        <b-collapse
          v-if="entry.length > 300"
          :open="false"
          position="is-bottom"
          aria-id="contentIdForA11y4"
        >
          <template #trigger="props">
            <a aria-controls="contentIdForA11y4" :aria-expanded="props.open">
              <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
              {{ !props.open ? 'Read More...' : 'Read Less' }}
            </a>
          </template>
          <p>
            {{ entry.length > 300 ? entry.substring(300, entry.length) : entry }}
          </p>
        </b-collapse>
      </span>
    </td>
  </tr>
</template>

<script>
import _set from 'lodash/set'

export default {
  props: {
    columns: Array,
    condensed: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    readMoreColumn: {
      type: String,
      default: ''
    },
    htmlColumn: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    expandableRows: Boolean,
    focusable: Boolean,
    selected: Boolean,
    rowObj: Object,
    rowIndex: Number,
    onClick: {
      type: Function,
      default: () => {}
    },
    onExpand: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    row() {
      const rowObj = this.rowObj

      for (let key in rowObj) {
        if (key === '_dataId') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dataId = rowObj[key]

          delete this.rowObj[key]
        }
      }

      return this.rowObj
    }
  },

  watch: {
    expanded() {
      if (this.expanded) {
        this.$set(this.styles.icon, 'transform', 'rotate(90deg)')
      } else {
        this.$set(this.styles.icon, 'transform', 'rotate(0deg)')
      }
    }
  },

  data() {
    const condensed = this.condensed
    var tdFontSize = '16px'

    if (condensed) {
      tdFontSize = '13px'
    }

    let iconTransform = 'rotate(0deg)'
    if (this.expanded) {
      iconTransform = 'rotate(90deg)'
    }

    return {
      dataId: null,
      styles: {
        row: {
          opacity: 0,
          transition: 'opacity .5s'
        },
        td: {
          fontSize: tdFontSize
          // transition: 'background .3s, color .3s'
        },
        icon: {
          cursor: 'pointer',
          transition: 'transform .5s',
          transform: iconTransform
        }
      }
    }
  },

  methods: {
    expand: function() {},
    rowClicked: function(params) {
      this.onClick(params)
    }
  },
  mounted: function() {
    setTimeout(() => {
      _set(this, 'styles.row.opacity', 1)
    }, 0)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.mobile-label {
  display: none;
}

.expand-icon-container {
  i {
    transition: transform 0.5s;
  }

  &.expanded i {
    transform: rotate(90deg);
  }
}

@media screen and (max-width: $desktop) {
  tbody {
    tr {
      margin-bottom: 8px;
    }
  }

  tr {
    display: block;

    td {
      display: flex;
      justify-content: space-between;
      padding: 4px 12px;
      align-items: center;

      .mobile-label {
        display: block;
      }
    }
  }
}

tbody {
  tr {
    border: 0;
    box-shadow: 0 0 3px 0px #0000002e;
    border-radius: 5px;

    .expandable-arrow-icon {
      color: $main-color;
    }

    &.row-is-selected {
      .expandable-arrow-icon {
        color: white !important;
      }

      td {
        background: $main-color;
        color: white;
      }
    }

    th:first-child,
    td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    th:last-child,
    td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  td {
    background: #fff;
  }
}

td,
th {
  border: 0;
  padding: 0.9em 0.75em;
  vertical-align: middle;
}
</style>
